export default [
  {
    header: 'menu.properties.header',
  },

  /*
    *
    *  Menu Items
    * */
  {
    title: 'menu.properties.children.all_properties',
    route: 'all_properties',
    icon: 'GridIcon',
  },
  {
    title: 'menu.properties.children.new_property',
    route: 'new_property',
    icon: 'PlusIcon',
  },
]

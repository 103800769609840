export default [
  {
    header: 'menu.home.header',
  },
  {
    title: 'menu.home.children.dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
]
